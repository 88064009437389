import React from "react"

import Index from "../components/index"
import Seo from "../components/other/seo"
import Container from "../components/layout/container";
import PageHeader from "../components/elements/PageHeader";
import ButtonLink from "../components/elements/ButtonLink";
import {
  BlockWithIcon,
  BlockWithIconIcon,
  BlockWithIconText,
  BlockWithIconWrap,
} from "../components/elements/BlockWithIcon";

const Insurers = () => (
  <Index>
    <Seo title="For Health Insurers" />
    <PageHeader>
      <h1>For Health Insurers</h1>
      <h2>Eliminate Guesswork for More Accurate Decision-Making</h2>
      <p className="no-margins">
        <ButtonLink href="/request-demo" text="Schedule a Demo" type="primary" />
      </p>
    </PageHeader>
    <Container>
      <div id="payors-highlight">
        <blockquote>
          <p className="font-light lead">
            "With InCircle, members will always have access to the data <br className="hide-large" />
            points that led to the decision, along with a summary of findings <br className="hide-large" />
            to help educate the provider, the payor and the patient. <br className="hide-large" />
            We pay attention to these details, so you don’t have to."
          </p>
          <footer>
            <b>Jonathan Katz, MD</b> <br />
            Forbes Norris ALS/MDA Center
          </footer>
        </blockquote>
        <div>
          <p>
            <b>A Representative Patient Highlight</b>
          </p>
          <p>
            In one patient review, IVIG was approved by insurance and continued for several years despite reviews that showed a lack of adequate response at reauthorization. <b>Total coast to health insurer: <i>in excess of $1,400,000</i>.</b> When this same case was processed via InCircle, first follow-up results suggested discontinuation due to lack of response. <b>Resulting cost avoidance = $400,000</b>.
          </p>
        </div>
      </div>
    </Container>
    <Container>
      <BlockWithIconWrap layout="vertical">
        <BlockWithIcon>
          <BlockWithIconIcon iconName="checklist" />
          <BlockWithIconText extraClasses="align-left">
            <h2>A Model that Minimizes <br />Misdiagnosis</h2>
            <p>
              InCircle combines the knowledge of key opinion leaders with the <br className="hide-large" />
              data needed to make the right prescribing decision. This model can <br className="hide-large" />
              not only lower the rates of misdiagnosis and the over-utilization <br className="hide-large" />
              of IG, it can also be instrumental in minimizing waste <br className="hide-large" />
              and lowering costs.
            </p>
          </BlockWithIconText>
        </BlockWithIcon>
        <BlockWithIcon>
          <BlockWithIconIcon iconName="stopwatch" />
          <BlockWithIconText extraClasses="align-left">
            <h2>Real Experts. Real Data. <br />In Real Time.</h2>
            <p>
              By leveraging the experience and knowledge of a panel of expert <br className="hide-large" />
              reviewers who have access to real data, InCircle alleviates the <br className="hide-large" />
              burden on prescribing physicians who are currently tasked with <br className="hide-large" />
              making accurate diagnoses without access to the data and <br className="hide-large" />
              longitudinal records to do so. In addition, InCircle gives payors a <br className="hide-large" />
              transparent and consistent view of the decision-making process, <br className="hide-large" />
              while providing employers with a desperately needed solution to <br className="hide-large" />
              control drug spend.
            </p>
          </BlockWithIconText>
        </BlockWithIcon>
      </BlockWithIconWrap>
    </Container>
  </Index>
)

export default Insurers
